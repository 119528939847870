
//import {  Link } from "react-router-dom";
//import ReactDOM from 'react-dom';
import React, { useState } from "react";

import infoData from "../data/info1";
import styles from "./Program.module.css";
import Table from "../TableInfo1";


const mystyle = {
  color: "red", 
  lineHeight:"1.4em",
  fontWeight: "400",
  color: "#200A58",
  fontSize: "38px",
  fontFamily: "Source Sans Pro, serif",
  textAlign:"center"
};


const Infolog1 = () => {
  const [info] = useState([...infoData]);
  const [searchQuery, setSearchQuery] = useState('');

const handleSearch = (e) => {
  setSearchQuery(e.target.value);
};
const items2 = [...info]
.filter((item) => {
  return (
    item.topic.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.location.toLowerCase().includes(searchQuery.toLowerCase()) 
  );
})

 
 

  return ( <div>
    <section className="infolog1--text"> 
    <h1 style={mystyle}> Infolog </h1>
    
    </section>
    <div>
     
    <main className={styles.container}>
      
      <div className={styles.wrapper}>
      <input 
              type="text"
              placeholder="Search Topic, Location or Description..."
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: "10px",
               
                border: "1px solid #ccc",
                fontFamily: "Source Sans Pro",
                fontSize: "14px",
                width: "400px",
                float: "right", marginBottom: "5px" 
              }}
            />

    
        <Table data={items2} rowsPerPage={4} />
      </div>
    </main>
    </div>
    </div>
  );
};

export default Infolog1;
