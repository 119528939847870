import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Card from './Card';
import csr from './csr';
import esg from '../images/esgDay.png';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  description2,
  buttonLabel,
  imgStart,
  darkMode
}) {
  const cards = csr.map(item => {
    return (
        <Card
            key={item.id}
            heading={item.heading}
            text={item.text}
            date={item.date}
            time={item.time}
            link={item.link}
            readMore={item.readMore}            
        />
    )
})        

  return (
    <>
    {console.log(csr)}
      <div
        className={darkMode ? 'home__hero-section darkBg' : (lightBg ? 'home__hero-section' : 'home__hero-section darkBg')}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className={lightText ? 'top-line' : 'top-line dark'}>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <div className={lightTextDesc ? 'paragraph' : 'paragraph dark'}>
                  <p>{description}</p>
                  <p>{description2}</p>
                </div>
                
                <div style={{display: "flex"}}>
                  {cards}
                </div>
               
                
              </div>
            </div>
            
            <div className='col'>
              <div className='home__hero-img-wrapper'>
                <img className='home__hero-img' src={esg} />
              </div>
            </div>
            <Link to='/program'>
              <Button buttonSize='btn--wide' buttonColor={darkMode ? 'red dark' : 'red'}>
                {buttonLabel} {/*{<span class="material-symbols-outlined">arrow_right_alt</span>}*/}
              </Button>
            </Link>
           

           
          </div>
          
        </div>
       
      </div>
    </>
  );
}

export default HeroSection;