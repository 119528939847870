import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export const InfoLogData = ({ token }) => {

  const {REACT_APP_API_URL} = process.env
  const {REACT_APP_API_MASTER} = process.env
  var url =
  `${REACT_APP_API_URL}csrInfoLog?code=${REACT_APP_API_MASTER}`
  const [data, setData] = useState({
    topic: "",
    location: "",
    description: "",
    link: "",
    label: "",
    link2: "",
    label2: "",
  });

  const headers = {
    'Authorization': "Bearer " + token,
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }

  function submit(e) {
    e.preventDefault();
    Axios.post(
      url,
      {
        topic: data.topic,
        location: data.location,
        description: data.description,
        link: data.link,
        label: data.label,
        link2: data.link,
        label2: data.label,
      },
      {
        headers: headers,
      }
    ).then((res) => {
      console.log(res.data);
    });

    toast.success("Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });

    setData({
      Topic: "",
      location: "",
      description: "",
      link: "",
      label: "",
      link2: "",
      label2: "",
    });
  }

  return (
    <>
      <div className="formDiv">
        <form onSubmit={(e) => submit(e)} id="entryForm">
          <label for="topic" className="formLabel">
            Topic
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.topic}
            type="text"
            id="topic"
            name="topic"
            placeholder="topic.."
            className="formInput"
          ></input>

          <label for="location" className="formLabel">
            Location
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.location}
            type="text"
            id="location"
            name="location"
            placeholder="Location.."
            className="formInput"
          ></input>

          <label for="description" className="formLabel">
            Description
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.description}
            type="text"
            id="description"
            name="description"
            placeholder="Description of activity.."
            className="formInput"
          ></input>

          <label for="link" className="formLabel">
            Link
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.link}
            type="url"
            id="link"
            name="link"
            placeholder="Link"
            className="formInput"
          ></input>

          <label for="label" className="formLabel">
            Label
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.label}
            type="label"
            id="label"
            name="label"
            placeholder="Label for the link.."
            className="formInput"
          ></input>

          <label for="link2" className="formLabel">
            Second Link
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.link2}
            type="url"
            id="link2"
            name="link2"
            placeholder="Link2"
            className="formInput"
          ></input>

          <label for="label2" className="formLabel">
            Label
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.label2}
            type="label2"
            id="label2"
            name="label2"
            placeholder="Label for the link.."
            className="formInput"
          ></input>

          <input type="submit" value="Submit" className="formSubmit" />
        </form>
      </div>
    </>
  );
};
