import React, { useState } from "react";
import UpperCard from "../Program/UpperCard";
import "./DataEntryStyle.css";
import { ProgramData } from "../Program/ProgramData";
import { InfoLogData } from "../InfoLog/InfoLogData";
import { PastActivitiesData } from "../PastActivities/PastActivitiesData";
import { ContactData } from "../Contact/ContactData";

const DataEntry = ({token}) => {
    const [csrTableComponent, setcsrTableComponent] = useState("option");

  const handleChange = (event) => {
    setcsrTableComponent(event.target.value);
  };

  return (
    <>
      <UpperCard title="Data Entry" />
      <div className="formDiv">
        <form>
          <label for="formEntry" className="formLabel">
            Select Table To Enter Data
          </label>
          <select
            id="table"
            name="table"
            value={csrTableComponent}
            onChange={handleChange}
            className="formSelect"
          >
            <option value="select">-- Select Option --</option>
            <option value="program">CSR Program</option>
            <option value="infoLog">CSR InfoLog</option>
            <option value="pastActivities">CSR Past Activities </option>
            <option value="contacts">CSR Contacts </option>
          </select>
        </form>

        {csrTableComponent === "program" ? (
          <ProgramData token={token} />
        ) : (
          <p></p>
        )}
        {csrTableComponent === "infoLog" ? (
          <InfoLogData token={token} />
        ) : (
          <p></p>
        )}
        {csrTableComponent === "pastActivities" ? (
          <PastActivitiesData token={token} />
        ) : (
          <p></p>
        )}
        {csrTableComponent === "contacts" ? (
          <ContactData token={token} />
        ) : (
          <p></p>
        )}
      </div>
    </>
  );
};


export default DataEntry
