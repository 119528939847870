import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import useTable from "../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";


import { initializeIcons, IconButton } from '@fluentui/react';

const Table = ({ data, handleSort, sortOrder }) => {
  const rowsPerPage = 5; // fixed number of rows per page
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Activity</th>
            <th className={styles.tableHeader}>
      <div style={{display: "flex"}}>
        BU
        <button onClick={() => handleSort('bu')} style={{ marginLeft: '0.25rem' }}>
          <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div>
    </th>
    <th className={styles.tableHeader}>
      <div style={{display: "flex"}}>
        Location 
        <button onClick={() => handleSort('location')} style={{ marginLeft: '0.25rem' }}>
        <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div>
    </th>          
    <th className={styles.tableHeader}><div style={{display: "flex"}}>
  Start&nbsp;date 
  <button onClick={() => handleSort('startDate')} style={{ marginLeft: '0.25rem' }}>
  <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
  </button></div>
</th>
            <th className={styles.tableHeader}>End date</th>
            <th className={styles.tableHeader}>Time</th>
            <th className={styles.tableHeader}>Description</th>
            <th className={styles.tableHeader}>Contact</th>
          </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <tr className={styles.tableRowItems} key={el.id}>
              <td className={styles.tableCell}>{el.activity}</td>
              <td className={styles.tableCell}>{el.bu}</td>
              <td className={styles.tableCell}>{el.location}</td>
              <td className={styles.tableCell}>{el.startDate}</td>
              <td className={styles.tableCell}>{el.endDate}</td>
              <td className={styles.tableCell}>{el.time}</td>
              <td className={`${styles.tableCell} ${styles.description}`}>
                {ReactHtmlParser(
                  el.description
                    .replace(
                      /(https?:\/\/[^\s]+)/g,
                      (match) =>
                        `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a><br>`
                    )
                    .replace(
                      /Please register via this link/gi,
                      `<a href="https://cgi.wenabi.com/#/profile/association/3697/intv/19514" target="_blank" rel="noopener noreferrer">Please register via this link</a>`
                    )
                    .replace(
                      /Bitte registriert Euch hier/gi,
                      `<a href="https://cgi.wenabi.com/#/profile/association/3697/intv/19514" target="_blank" rel="noopener noreferrer">Bitte registriert Euch hier</a>`
                    )
                )}
              </td>      
              <td className={styles.tableCell}>
                <a href={`mailto:${el.contact}`}>{el.contact}</a>
                {el.contact2 && (
                  <>
                    <hr />
                    <a href={`mailto:${el.contact2}`}>{el.contact2}</a>
                  </>
                )}
                {el.contact3 && (
                  <>
                    <hr />
                    <a href={`mailto:${el.contact3}`}>{el.contact3}</a>
                  </>
                )}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;