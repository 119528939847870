export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Welcome to the SCE Sustainability App',
    description:
      ' ESG - Environmental, Social and Governance, aslo known as CSR (Corporate Social Responsibility) is a long established tradition at CGI where we give back to the community. The 2023 Sustainability Week will bring the important topic of climate into focus. The week will feature a great variety of activities across our SCE locations. Join in and experience the commitment of making a difference!',
    description2:
      '2023 Sustainability Week offers more than 40 activities. From local on site happenings to online tasks by eminent experts on environmental issues: Your ESG teams have made sure to create activities that are fun, as well as meaningful. Find the activity that is right for you and join in! Be sure not to miss our three CSE-wide events:',
    
    buttonLabel: 'Explore',
    imgStart: '',
    img: "process.env.PUBLIC_URL + '/CGI_logo_color_rgb.png'",
    alt: 'Torsten Strass'
  };