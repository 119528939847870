export default [
    {
      "id": 1,
      "esgbu": "ESG SBU SCE",
      "responsibleMember": "Cecilia Pfannenstill",
      "contact": "cecilia.pfannenstill@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 2,
      "esgbu": "ESG DE C&S",
      "responsibleMember": "Inge Anna Fischer",
      "contact": "ingeanna.fischer@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 3,
      "esgbu": "ESG DE GTO",
      "responsibleMember": "Patricia Reyes-Duenas",
      "contact": "patricia.reyes-duenas@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 4,
      "esgbu": "ESG DE North",
      "responsibleMember": "Sandra Maree",
      "contact": "sandra.maree@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 5,
      "esgbu": "ESG DE West",
      "responsibleMember": "Frank Roscher",
      "contact": "frank.roscher@cgi.com",
      "responsibleMember2": "Lisa Theismann",
      "contact2": "lisa.theismann@cgi.com",
      "responsibleMember3": "Katrin Tams",
      "contact3": "katrin.tams@cgi.com"
    },
    {
      "id": 6,
      "esgbu": "ESG NO",
      "responsibleMember": "Karl Solberg",
      "contact": "karl.solberg@cgi.com",
      "responsibleMember2": "Inger Brunvaer Kvale",
      "contact2": "inger.brunvaer.kvale@cgi.com",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 7,
      "esgbu": "ESG Scandinavia GTO",
      "responsibleMember": "Helena B. Eriksson",
      "contact": "helena.b.eriksson@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 8,
      "esgbu": "ESG SE Göteborg & Central",
      "responsibleMember": "Anna Haggmeijer",
      "contact": "anna.haggmeijer@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 9,
      "esgbu": "ESG SE IP & BPS and GDC",
      "responsibleMember": "Anneli Akerdahl",
      "contact": "anneli.akerdahl@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 10,
      "esgbu": "ESG SE Malmö & South",
      "responsibleMember": "Margita Johansson",
      "contact": "margita.johansson@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    },
    {
      "id": 11,
      "esgbu": "ESG SE North",
      "responsibleMember": "Kersti Ringi",
      "contact": "kersti.ringi@cgi.com",
      "responsibleMember2": "Jessie Lofgren",
      "contact2": "jessie.lofgren@cgi.com",
      "responsibleMember3": "Jenni Jannering",
      "contact3": "jenni.jannering@cgi.com"
    },
    {
      "id": 12,
      "esgbu": "ESG SE Stockholm",
      "responsibleMember": "An Reyes",
      "contact": "an.reyes@cgi.com",
      "responsibleMember2": "",
      "contact2": "",
      "responsibleMember3": "",
      "contact3": ""
    }
  ]