import React, { useState } from "react";

import useTable from "../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";

const Table = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Topic</th>
            <th className={styles.tableHeader}>Location</th>
            <th className={styles.tableHeader}>Description</th>
            <th className={styles.tableHeader}>Link</th>
            
          </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <tr className={styles.tableRowItems} key={el.id}>
              <td className={styles.tableCell}>{el.topic}</td>
              <td className={styles.tableCell}>{el.location}</td>
              <td className={styles.tableCell}>{el.description}</td>
              <td className={styles.tableCell}> <a href="https://www.cgi.com/en">{el.link}</a></td>
              
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;