import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export const ContactData = ({ token }) => {

  const {REACT_APP_API_URL} = process.env
  const {REACT_APP_API_MASTER} = process.env
  

  var url = `${REACT_APP_API_URL}contact?code=${REACT_APP_API_MASTER}`

  const [data, setData] = useState({
    location: "",
    member: "",
    email: "",
  });

  const headers = {
    'Authorization': "Bearer " + token,
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }

  function submit(e) {
    e.preventDefault();
    Axios.post(
      url,
      {
        location: data.location,
        member: data.member,
        email: data.email,
      },
      {
        headers: headers,
      }
    ).then((res) => {
      console.log(res.data);
    });

    toast.success("Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });

    setData({
      location: "",
      member: "",
      email: "",
    });
  }

  return (
    <>
      <div className="formDiv">
        <form onSubmit={(e) => submit(e)} id="entryForm">
          <label for="location" className="formLabel">
            Location
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.location}
            type="text"
            id="location"
            name="location"
            placeholder="Location.."
            className="formInput"
          ></input>

          <label for="member" className="formLabel">
            Member
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.member}
            type="text"
            id="member"
            name="member"
            placeholder="Member name.."
            className="formInput"
          ></input>

          <label for="email" className="formLabel">
            Email
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.email}
            type="email"
            id="email"
            name="email"
            placeholder="Member Email.."
            className="formInput"
          ></input>

          <input type="submit" value="Submit" className="formSubmit" />
        </form>
      </div>
    </>
  );
};
