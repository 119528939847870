import React from "react";
import "./card.css";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";

export default function Card(props) {
  return (
    <div className="card">
      <h3 className="title">{props.heading}</h3>
      <p className="text">
        {props.text}
        {props.readMore && (
          <>
            {" "}
            <a href={props.readMore} target="_blank" rel="noopener noreferrer" className="read-more">
              Read more
            </a>
          </>
        )}
      </p>
      <div className="date-time-container">
        <div className="date">
          <FaRegCalendarAlt size={14} color="#555" />
          <span style={{ marginLeft: "5px" }}>{props.date}</span>
        </div>
        {props.time && (
          <div className="time">
            <FaRegClock size={14} color="#555" />
            <span style={{ marginLeft: "5px" }}>{props.time}</span>
          </div>
        )}
      </div>
      <div className="button-container">
        <button className="join-button" onClick={() => window.open(props.link)}>Join Here</button>
      </div>
    </div>
  );
}
