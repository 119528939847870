import React, { useState } from "react";

import useTable from "../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";

const Table = ({ data, rowsPerPage, handleSort, sortOrder }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <> <div className="table-container">
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Activity</th>
            <th className={styles.tableHeader}> <div style={{display: "flex"}}>
        BU
        <button onClick={() => handleSort('bu')} style={{ marginLeft: '0.25rem' }}>
          <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div></th>
            <th className={styles.tableHeader}><div style={{display: "flex"}}>
        Location 
        <button onClick={() => handleSort('location')} style={{ marginLeft: '0.25rem' }}>
        <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div></th>
            <th className={styles.tableHeader}>Description</th>
            <th className={styles.tableHeader}>Contact</th>
            
          </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <tr className={styles.tableRowItems} key={el.id}>
              <td className={styles.tableCell}>{el.activity}</td>
              <td className={styles.tableCell}>{el.bu}</td>
              <td className={styles.tableCell}>{el.location}</td>
              <td className={styles.tableCell}>{el.description}</td>
              <td className={styles.tableCell}>{el.contact}</td>
              
            </tr>
          ))}
        </tbody> 
      </table> </div>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;