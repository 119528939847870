// Please note the date format. Other formats are not handled yet.

export default [
    {
      "id": 1,
      "activity": "Collecting crown corks for a good cause -",
      "bu": "Germany Central and South",
      "location": "Leinfelden-Echterdingen",
      "startDate": "2/15/2023",
      "endDate": "5/31/2023",
      "time": "All day",
      "description": "Fundraising campaign for the benefit of the Förderverein für krebskranke Kinder Tübingen e.V. (support association for children with cancer). One of our members has already collected 1.5 kg in a short time in connection with an event - where there is a lot of crown cork waste. A small effort, in the end, can raise a big goal and a considerable amount of donations. Therefore, we would like to appeal to you all to participate in our collection campaign until the end of May 2023. In the location Leinfelden-Echterdingen you will find a collection container in the kitchen that is just waiting to be filled. You can deliver your crown caps here at any time. If you want to participate from other locations, please contact Tanja Schmid. \nREGISTRATION: If you want to support this initiative, please register in the CGI For Good Tool. Registration is only for the purpose of tracking participation by the organization team. Please use this link for registration: https://cgi.wenabi.com/#/intv-global/deed/1578",
      "contact": "tanja.schmid@cgi.com"
    },
    {
      "id": 2,
      "activity": "Collecting crown caps for Till Eulenspiegel's home, the Till Eulenspiegel Museum in Schöppenstedt",
      "bu": "Germany North",
      "location": "Braunschweig",
      "startDate": "2/23/2023",
      "endDate": "ongoing",
      "time": "",
      "description": "Collecting crown caps for Till Eulenspiegel's home, the Till Eulenspiegel Museum in Schöppenstedt. This recycling is also good for the environment. For more details see https://www.eulenspiegel-museum.de/mitmachen/",
      "contact": "julian.stolte@cgi.com"
    },
    {
      "id": 3,
      "activity": "Collecting of secondhand clothes, textiles and toys for the DRK Kaufbar",
      "bu": "Germany North",
      "location": "Braunschweig",
      "startDate": "3/6/2023",
      "endDate": "3/23/2023",
      "time": "",
      "description": "Collecting of secondhand clothes, textiles and toys: as every year we collect for the DRK Kaufbar. Handing over of the donations in this sustainability week on 23.03.2023.",
      "contact": "tanja.gerbig@cgi.com"
    },
    {
      "id": 4,
      "activity": "Collecting old newspapers for animal welfare",
      "bu": "Germany North",
      "location": "Braunschweig",
      "startDate": "3/6/2023",
      "endDate": "ongoing",
      "time": "",
      "description": "We will collect old newspapers in the location that can be used to line aviaries, cages, etc. You will find out exactly where the collection box will be located in the March CSR newsletter for Braunschweig.",
      "contact": "leandra.franke@cgi.com",
      "contact2": "sarah.weigel@cgi.com"
    },
    {
      "id": 5,
      "activity": "Clean Up Day with K.R.A.K.E.",
      "bu": "Germany West",
      "location": "Köln",
      "startDate": "3/18/2023",
      "endDate": "3/18/2023",
      "time": "12:00 – 16:00",
      "description": "Müll sammeln und gleichzeitig Bäume pflanzen? Das ist die Idee des gemeinsamen Aktionstags von KRAKE, Planted und der Volksbank Köln Bonn.\n \n Wälder sind Lebensstifter, Sauerstofflieferanten und wichtige CO2-Speicher. Darüber hinaus sind sie wichtige Naherholungsgebiete für viele Kölnerinnen und Kölner. Wo könnte man besser abschalten als bei einem Spaziergang durch den majestätischen Königsforst, hoch zum Monte Troodelöh?\n \n Doch unsere Wälder leiden. Die zunehmend trockenen Sommer, Stürme, Krankheiten und auch unser eigenes Verhalten setzen ihnen zu. Zum Tag des Waldes wollen wir unseren Wäldern gemeinsam mit Euch etwas Gutes tun. Am 18.03.2023 werden wir in einer großen Müllsammelaktion den Stadtwald von Plastikmüll und anderem Unrat befreien. Für jeden an diesem Tag gesammelten Müllsack spendet die Volksbank einen Baum. Die Setzlinge werden wir dann anschließend im Herbst zusammen mit Planted im Königsforst oder in einem anderen regionalen Wald pflanzen. Clean Up Day \n For every bag of trash collected that day, Volksbank donates a tree.\n \n Bäume statt Müll - KRAKE e.V. https://krake.koeln/event/baeume-statt-muell/",
      "contact": "clara.hardt@cgi.com"
    },
    {
      "id": 6,
      "activity": "Kölle putzmunter - Worringen",
      "bu": "Germany West",
      "location": "Köln",
      "startDate": "3/18/2023",
      "endDate": "3/18/2023",
      "time": "10:00 - 13:00",
      "description": "Clean Up Day\n Kölle putzmunter: RhineCleanUp - Rheinaue Worringen - AWB Köln (awbkoeln.de) https://www.awbkoeln.de/koelle-putzmunter/aktion/?tx_koelleputzmunter_pi1%5Buid%5D=2664",
      "contact": "Monika.herweg@cgi.com"
    },
    {
      "id": 7,
      "activity": "Kölle putzmunter - Weidenpesch",
      "bu": "Germany West",
      "location": "Köln",
      "startDate": "3/18/2023",
      "endDate": "3/18/2023",
      "time": "13:30 - 15:30",
      "description": "Clean Up Day \n Kölle putzmunter: saubere Grünflächen - AWB Köln (awbkoeln.de) https://www.awbkoeln.de/koelle-putzmunter/aktion/?tx_koelleputzmunter_pi1%5Buid%5D=2664",
      "contact": "Monika.herweg@cgi.com"
    },
    {
        "id": 41,
        "activity": "AgileDX-Sustainability",
        "bu": "SBU",
        "location": "Everywhere",
        "startDate": "3/20/2023",
        "endDate": "3/20/2023",
        "time": "13:00 - 13:45",
        "description": "Introduction to CGIs solution on how to collect and report ESG figures efficiently - \"AgileDX-Sustainability\" \n \n You are going to learn why we created the AgileDX-Sustainability, how it works (life demo) and we will have time for questions\n \n Agenda:\n 13:00 – 13:15 Introduction Nicole\n 13:15 – 13:30 Demo Tim & Frank\n 13:30 – 13:45 Q&A all\n \n https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTBmNmZmMDItNGUxYy00MWRhLWI4MzMtYzAyYTNmMjlkMDE2%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%225ffe4912-3922-4988-ab07-41f0dab9ae18%22%7d\n Besprechungs-ID: 283 026 166 071 \n Passcode: yrsrXn",
        "contact": ""
      },
    {
      "id": 8,
      "activity": "Photo Challenge: Post a photo – make a difference!",
      "bu": "SBU",
      "location": "Everywhere",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "All Day",
      "description": "The aim of the challenge is to collect and vote on <strong>photos</strong> sent in by members taking part in <strong>Sustainability Week</strong> activities. This channel is SBU wide in SCE and can be used by you to publish <strong>photos taken during Sustainability Week</strong>. \n \n <strong>You want to take us up on the challenge? Great!</strong>\n \n Please proceed as follows:\n \n <strong>When?</strong> Post your photo(s) between March 20th and 26th (Deadline on 26th: 12 o'clock/Noon).\n \n <strong>What?</strong> Your photo(s) can be an amazing illustration of the topic of sustainability, a poignant comment on climate change, or mabye simply of having fun with your team at a great Sustainability Week activity (other ESG activities taking place during the week can be a topic, as well).\n \n <strong>How?</strong> Any member can vote for one or more photos by giving it a \"thumbs up\".\n \n <strong>... and the winner is: The photo which has been given the highest number of \"thumbs up\" will be the winner</strong>. To be determined and announced on march 27th.\n \n <strong>But the real winner is:</strong> You favourite NGO (= Non Governmental Organization). Because if you win, <strong>CGI will give €500 to the good cause of your choosing.</strong>\n \n https://teams.microsoft.com/l/team/19%3aapaIzKJ6dxTl4FLThOqrB3wWUC18tfxaaldfxc4UTuE1%40thread.tacv2/conversations?groupId=f466d0c8-6a01-4683-8b38-422e6caee325&tenantId=b9fec68c-c92d-461e-9a97-3d03a0f18b82",
      "contact": ""
    },
    {
      "id": 9,
      "activity": "Pledge Challenge: Pledge to be your \"sustainable\" best",
      "bu": "SBU",
      "location": "Everywhere",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "All Day",
      "description": "The aim of the challenge is to collect and vote on <strong>personal challenges</strong> sent in by members during <strong>Sustainability Week</strong>. The channel is SBU wide in SCE and can be used by you to <strong>post your claim to \"sustainability fame\" during Sustainability Week. \n \n You want to take us up on the challenge? Great!</strong>\n \n Please proceed as follows:\n \n <strong>When?</strong> Post your pledge or statement between March 20th and 26th (Deadline on 26th: 12 o'clock/Noon).\n \n <strong>What?</strong> Get inspired, be an inspiration to others, post for example:\n \n \"I will have one vegetarian day per week for one year.\"\n \"I will always compensate my personal flights from now on.\"\n \"When I leave the office, I make a quick check, if everything electrical that can be turned off, is turned off.\"\n \"I picked up litter amounting to the weight of 50 kg during Sustainability Week.\"\n \"I share with you all my favourite vegetarian recipe: Two large potatoes, a carrot …\"\n \n <strong>How?<strong> Any member can vote for one or more pledges by giving it a \"thumbs up\".\n \n <strong>... and the winner is: The pledge which has been given the highest number of \"thumbs up\" will be the winner</strong>. To be determined and announced on march 27th.\n \n <strong>But the real winner is</strong>: You favourite NGO (= Non Governmental Organization). Because if you win, <strong>CGI will give €500 to the good cause of your choosing</strong>.\n \n https://teams.microsoft.com/l/team/19%3aapaIzKJ6dxTl4FLThOqrB3wWUC18tfxaaldfxc4UTuE1%40thread.tacv2/conversations?groupId=f466d0c8-6a01-4683-8b38-422e6caee325&tenantId=b9fec68c-c92d-461e-9a97-3d03a0f18b82",
      "contact": ""
    },
    {
      "id": 10,
      "activity": "Berlin goes Green(er): Making a difference in three days",
      "bu": "Germany West",
      "location": "Berlin",
      "startDate": "3/20/2023",
      "endDate": "3/23/2023",
      "time": "12:00-13 Uhr",
      "description": "<strong>Berlin goes Green(er): Making a difference in three days\n 3 days, 60 min</strong>.\n We want to meet up around lunch time on four consecutive days. Our aim is to brainstorm, plan, and act on our ideas: Can we make a difference with regard to the Berlin location when it comes to sustainability? How do we make a difference, be it ever so small! We meet at the location and go out for lunch together to vegetarian restaurants in the vicinity. ESG will help with the \"catering\". Rating and recommending the restaurant(s) of our choice to our co-workers will be the first result of our activity. Let's (m)eet ;-)\nIf you want to take part, please contact the ESG representative/s responsible (see “Contacts”). They will get back to you with further information.",
      "contact": "katrin.tams@cgi.com"
    },
    {
      "id": 11,
      "activity": "Challenges for a more sustainable lifestyle!",
      "bu": "SBU",
      "location": "Stockholm",
      "startDate": "3/20/2023",
      "endDate": "3/26/2023",
      "time": "All week",
      "description": "<strong>Do you want to know how you can make more sustainable choices in your everyday life? Then try out some challenges this week! \n \n How do I participate?</strong> Join the SBU Teams Channel to get more information about each challenge and to share your achievements with colleagues all over the Sub-BU! \n \n <strong>What are the challenges?</strong> There are 6 different challenges you can participate in: \n \n 1. Become a vegetarian/vegan for a week! We will provide you with the recipes. \n \n 2. Don’t buy anything new for a week! Reduce your consumption-based emissions. \n \n 3. Leave the car at home – use public transportation, take a walk or take the bike to work and other activities! \n \n 4. Sign up for CGI for Good initiatives! Did you know that you have up to 8 hours for volunteering activities a year? \n \n 5. Recycle all your garbage – don't throw anything in the combustible waste bin for a week to get one step closer to a circular lifestyle! \n \n 6. For offices in the Stockholm area: Leave an item and send a donation at our Secondhand Corners! \n \n <strong>Are you curious to learn how these challenges increase your sustainability? Join the Teams Channel https://teams.microsoft.com/l/team/19%3aapaIzKJ6dxTl4FLThOqrB3wWUC18tfxaaldfxc4UTuE1%40thread.tacv2/conversations?groupId=f466d0c8-6a01-4683-8b38-422e6caee325&tenantId=b9fec68c-c92d-461e-9a97-3d03a0f18b82</strong>",
      "contact": "an.reyes@cgi.com"
    },
    {
      "id": 12,
      "activity": "Instagram take-over on sustainability",
      "bu": "Germany Central and South",
      "location": "Karlsruhe",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "All day",
      "description": "During \"Sustainability Week\", you can find out more about sustainability on the Instagram channel @cgideutschland, supported by the ESG@Karlsruhe working group. It will be shown how each of us can make our contribution to everyday life. The working group deals with various topics. From nutrition, water, and energy, waste separation to the environment, and consumer behavior. Look forward to interesting challenges and tricky quizzes where you can get active yourself. Join us and follow this link on Instagram: Link https://www.instagram.com/cgideutschland/ \n REGISTRATION: Please register for this initiative in the CGI For Good tool. Registration is only for the purpose of tracking participation by the organization team. Please use this link for registration: https://cgi.wenabi.com/#/intv-global/deed/1577",
      "contact": "Michelle.Grau@cgi.com"
    },
    {
      "id": 13,
      "activity": "Collect clothes to charity",
      "bu": "Local office: Linköping and Norrköping",
      "location": "Linköping and Norrköping",
      "startDate": "3/20/2023",
      "endDate": "3/31/2023",
      "time": "08:00-17:00",
      "description": "A Collect clothes corner will be created at the offices, to which members can bring clothes to be handed over to the organization \"Klimatklubben\" in both Linköping & Norrköping. Klimatklubben regularly organizes flea markets where the proceeds go to sustainable projects. For example, projects in Kenya to plant trees.",
      "contact": "lejla.salihu@cgi.com"
    },
    {
      "id": 14,
      "activity": "Reuse",
      "bu": "GTO&IPBPS - Kista",
      "location": "Stockholm",
      "startDate": "3/20/2023",
      "endDate": "3/31/2023",
      "time": "08:00-17:00",
      "description": "We love re-use! A “Re-use corner” will be created at the office in Stockholm, to which members can bring things for others to re-use. Please note that such things should be easy to bring home using local transportation. Anything that has been placed in the Re-use corner can be taken home by another member after a donation via the swish app/QR code to a charity organization.",
      "contact": "anneli.akerdahl@cgi.com",
      "contact2": "wanwisa.ottosson@cgi.com"
    },
    {
      "id": 15,
      "activity": "Litter picking either near your home or near the office.",
      "bu": "Norway",
      "location": "All offices in Norway/Everywhere",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "",
      "description": "The offices in Norway will have available equipment for litter picking (gloves and bags).",
      "contact": "inger.brunvaer.kvale@cgi.com"
    },
    {
      "id": 16,
      "activity": "Grow a sunflower for the bees",
      "bu": "Norway",
      "location": "All offices in Norway",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "",
      "description": "Support the bees - grow a sunflower! The offices in Norway will be handing out Sunflower seeds to members who want to try growing one this season.",
      "contact": "inger.brunvaer.kvale@cgi.com"
    },
    {
      "id": 17,
      "activity": "DKMS- Registration- Join us in the global fight against blood cancer",
      "bu": "Germany West",
      "location": "Darmstadt",
      "startDate": "3/20/2023",
      "endDate": "3/25/2023",
      "time": "",
      "description": "You can make a difference! Choose to be a potential lifesaver and help to fight blood cancer. Sign up to register as a potential blood stem cell donor! DKMS is an international nonprofit organization dedicated to the fight against blood cancer and blood disorders by creating awareness, recruiting stem cell donors to give those in need of a stem cell donation a second chance at life, raising funds to match donor registration costs, and supporting the improvement of blood cancer therapies by own research. Let your Darmstadt CSR Team ( birgit.koch@cgi.com) know if you are interested in getting registered at DKMS. We will help you with the registration and will cover the internal costs of DKMS for the registration of our Darmstadt members. Further information can be found on www.dkms.de",
      "contact": "Birgit.koch@cgi.com"
    },
    {
      "id": 18,
      "activity": "CGI-4-Member-Kids - SCRATCH Programming Course for kids (8-12 years)",
      "bu": "Germany West",
      "location": "Darmstadt",
      "startDate": "3/20/2023",
      "endDate": "3/20/2023",
      "time": "17:00-19:30",
      "description": "Coding with Scratch for kids (8-12 years)\n Dear CGI Space Darmstadt Team,\n we warmly invite you and your kids (nieces, nephews, godchildren,..) to a Scratch Programming Course.\n In this course, the children will have the chance to learn to program with Scratch (with parental guidance).\n Since we can only provide 15 places, we will take registrations on a first-come, first-served basis and, \n if necessary, create a waiting list. Children up to the age of 12 must be accompanied by an adult.\n Please bring your own laptop (CGI or private)\n • Date: Monday 20th March 2023\n • Time: 16:30 – 18:30 h \n • Place: CGI Darmstadt, Mornewegstrasse 30\n  Room: Galileo - 4.1 - max board room\n • Who: Children from 8 to 12 years old (or older) \n with one CGI parent or accompanied by a CGI member",
      "contact": "Birgit.koch@cgi.com"
    },
    {
      "id": 20,
      "activity": "Collection of Goods for Friedensdorf e.V.",
      "bu": "Germany West",
      "location": "Düsseldorf\n Bochum(Space)\nKöln",
      "startDate": "3/20/2023",
      "endDate": "3/24/2023",
      "time": "8:00 - 17:00",
      "description": "Friedensdorf has been helping the most innocent victims of war and conflict worldwide – the children. We will collect goods for them. This will be organized from the locations directly. For more information please contact Angelika for Bochum, Clara for Cologne and Lisa for Düsseldorf.",
      "contact": "lisa.theismann@cgi.com",
      "contact2" : "angelika.stalitza@cgi.com",
      "contact3" : "clara.hardt@cgi.com"
    },
    {
      "id": 21,
      "activity": "Key Kickoff about \"Sustainability Week 2023",
      "bu": "SBU",
      "location": "Everywhere",
      "startDate": "3/20/2023",
      "endDate": "3/20/2023",
      "time": "12:15 - 12:50",
      "description": "Welcome and introduction to the sustainability week with Cecilia Pfannenstill, Director ESG SBU SCE\n \n Kevin Linder SVP Finance and Treasury - CGI ESG strategy and plans:\n Investor relations\n ESG report and plan for development to an integrated report based on coming standards and laws - why is this of everyone’s concern?\n Main objectives for CGI and what is most important to clients and how can business/members get support\n \n Marion Braams, VP Social responsibility and sustainability/ESG - What is most important for you to do as a member?\n \n Welcome to join through this link:https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjBjOTRkNzQtZTNjOC00ZjVhLThlYjUtMDA1ZjgxZDVhZTI1%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%22343eb70c-4aa0-406f-b96a-f9730e0acb9a%22%7d",
      "contact": "cecilia.pfannenstill@cgi.com"
    },
    {
      "id": 2111,
      "activity": "Online lecture and discussion:  \"Climate change - what is it and what does it mean for us?\"",
      "bu": "North/GTO/Germany online",
      "location": "Everywhere",
      "startDate": "3/20/2023",
      "endDate": "3/20/2023",
      "time": "17:00 - 18:00",
      "description": "Online-Vortrag zum Thema Klimawandel. - Der Vortrag wird auf deutsch sein.\n Dr. Jennifer Sobiech-Wolf ist promovierte Klimawissenschaftlerin. Sie hat mehr als 12 Jahre am Alfred-Wegener-Institut, Helmholtz-Zentrum für Polar- und Meeresforschung sowie der Westfälischen Wilhelms-Universität Münster geforscht. Der Schwerpunkt ihrer Arbeiten liegt im Bereich der Grundlagenforschung in den Polargebieten. Sie hat an mehreren Forschungsexpeditionen in die Arktis und Antarktis teilgenommen.\n Link to Microsoft Teams Session: https://teams.microsoft.com/l/meetup-join/19%3ameeting_NTNmODBmZjEtNjQxYy00MWEzLWIxMjgtNGU4ODI1ODk2ZjMw%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%225c369621-baa1-4167-8bcf-80d21d85f9a6%22%7d",
      "contact": "olga.schwenecke@cgi.com"
    },
    {
      "id": 211,
      "activity": "Challenge: Be heard",
      "bu": "Norway",
      "location": "Trondheim",
      "startDate": "3/20/2023",
      "endDate": "3/26/2023",
      "time": "All day",
      "description": "Make your voice heard: Ask your favorite political party to have credible plans for the Paris agreement. You will find contact info on the internet: SoMe, DM, email, phone etc. Here are two templates to get you started: \n a) I could vote for you if you have credible plans for the Paris agreement! I am worried. \n b) I voted for you. Please deliver on the Paris agreement! I am worried.",
      "contact": "fredrik.bertilsson@cgi.com"
    },

    {
      "id": 22,
      "activity": "AI4Whales",
      "bu": "SBU",
      "location": "Everywhere",
      "startDate": "3/21/2023",
      "endDate": "3/21/2023",
      "time": "12:00 pm - 12:30 pm",
      "description": "Keynote about one of our Tech4Good initiative within ITC Munich that aims to use Earth Observation and Artificial Intelligence technologies to spot whales to avoid fatal collisions with ships. The session is fully remote via MS Teams. Every member is welcome to join this session. Website: https://www.cgi.com/en/article/sustainability/using-earth-observation-data-and-machine-learning-prevent-ship-whale-collisions\n REGISTRATION MS Teams session: https://teams.microsoft.com/l/meetup-join/19%3ameeting_NjkyZjRmZTgtOTlhYi00ZThjLWI0YTktYjc4ZGJhZWMzMTZh%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%2298bb8e0c-e0f8-4022-9a39-fc2e90991e20%22%7d",
      "contact": "morgan.dias.simao@cgi.com"
    },
    {
      "id": 23,
      "activity": "Sustainability Meetup",
      "bu": "Germany Central and South",
      "location": "Munich, Spixstreet 59",
      "startDate": "3/21/2023",
      "endDate": "3/21/2023",
      "time": "06:30 pm - 09:00 pm",
      "description": "Panel discussion with sustainability representatives from companies of various sizes and industries, where every speaker will provide a short presentation of the top sustainability priorities of their organizations. The focus will be on the use of solutions (technologies & methods) to achieve sustainable goals around circularity (innovation and design of products or business models) and reporting (ESG, EU Taxonomy). The discussion will be approx. 1.5 hours, and afterward networking dinner with vegan food is organized. Every member is welcome to join this session. REGISTRATION via CGI for Good Tool. Link: https://cgi.wenabi.com/#/profile/association/3697/intv/18877",
      "contact": "morgan.dias.simao@cgi.com"
    },
    {
      "id": 24,
      "activity": "Baue dein eigenes Insektenhotel / Build your own insect hotel [Upcycling, DIY]",
      "bu": "Germany West",
      "location": "Bochum",
      "startDate": "3/21/2023",
      "endDate": "3/21/2023",
      "time": "15:00-18:00",
      "description": "Wir bauen unser eigenes Insektenhotel (Upcycling), für das anschliessend bei euch ein sonniger Platz gesucht werden soll – geschützt von Wind und Regen, umgeben von insektenfreundlichen Pflanzen und Blumen. Bringt gerne eure eigenen Materialien mit (es gibt eine Liste), wir freuen uns auf eine fröhliche Bastelstunde. Treffpunkt: Bochum, Borgmannstr. 2, 44894 Bochum. Bitte registriert Euch hier über das CGI for Good Tool.\n   We will build our own insect hotels (upcycling), where afterwards you should find a sunny spot for - protected from rain and wind, surrounded by insect-friendly plants and flowers. You are welcome to collect and bring your own material (you get a list) and have fun together. Mtg at Bochum, Borgmannstr. 2, 44894 Bochum. Please register via this link on the CGI for Good tool.",
      "contact": "marlene.gerhardt@cgi.com"
    },        
    {
      "id": 25,
      "activity": "Lecture with Medveten Konsumtion",
      "bu": "BU Stockholm, GTO, IPBPS, Gothenburg & Central, Malmö & South, Norway and North",
      "location": "Stockholm",
      "startDate": "3/21/2023",
      "endDate": "3/21/2023",
      "time": "09:00-09:30",
      "description": "Welcome to an inspiring digital lecture in sustainable consumption by The Swedish Association for Responsible Consumption. \nResponsible Consumption wants to, through offering information, make it easier for everyone to make more responsible choices regarding the climate, health and fair trade. They believe in consumer power and want to inspire you as an individual, company or organization to reflect and act. That is more important now than ever before. \nThe lecture will include:\n Why sustainable consumption is important – what does the research say? \nHow do I become a responsible consumer?\nConcrete facts and advice \nLINK TO THE TEAMS EVENT: https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2NlYzMyZmMtNDc2My00ZDExLWI0Y2YtNTFkMDdjYzkzNWVh%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%2268e0e93b-e986-4149-b11d-168e07366385%22%7d",
      "contact": "an.reyes@cgi.com"
    },
    {
      "id": 251,
      "activity": "CGI-Bike-Leasing",
      "bu": "Germany",
      "location": "Online Meeting",
      "startDate": "3/21/2023",
      "endDate": "3/21/2023",
      "time": "13:00",
      "description": "Since 2017, CGI has made bike leasing available to its members through two of the largest providers. The concept offers an attractive financing model. Furthermore, CGI additionally takes over the rate for the comprehensive insurance for an ideal all-round protection for the bikes.\n On 21.03.2023 at 1 pm you will have the opportunity to ask Timm Heese your questions. He will be happy to introduce you to the concept of bike leasing and answer any questions you may have. Join here (https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZDZkZWNlYjQtZTcwNC00YjRjLWExYjctYjViMWMxNmNmYzJm%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%229a380ee1-dd88-443d-9ece-8c35b2751d92%22%7d).\n     Advantages:\n Financial savings \n- Sporty, nature-loving balance\n- CO2 savings\nFor more information on CGI bike leasing, the results of the latest satisfaction survey and a FAQ, click here (https://ensemble.ent.cgi.com/business/297537/BikeLeasing/SitePages/Community Home.aspx). This initiative is also mentioned in the CGI for Good tool (https://cgi.wenabi.com/#/intv-global/intv/19498).",
      "contact": "timm.heese@cgi.com"
    },
    {
      "id": 26,
      "activity": "Install insect hotels for more biodiversity",
      "bu": "Germany Central and South",
      "location": "Sulzbach",
      "startDate": "3/22/2023",
      "endDate": "3/22/2023",
      "time": "12:00 pm - 13:30 pm",
      "description": "Insect hotels are artificially created shelters, nesting, and overwintering helpers for insects. 10 of these insect hotels are built/hung up in the surroundings of our location in Sulzbach. One of our members who has experience with this matter and is himself a beekeeper accompanies the activity. All necessary material for the installation is available. Members are welcome to join this initiative and support the installation of the insect hotels. Meeting point is the CGI location, main entrance. REGISTRATION via the CGI for Good Tool. Link: https://cgi.wenabi.com/#/profile/association/3697/intv/18873",
      "contact": "johannes.sackmann@cgi.com"
    },
    {
      "id": 27,
      "activity": "Become a beekeeper - because bees need us",
      "bu": "Germany Central and South",
      "location": "Leinfelden-Echterdingen",
      "startDate": "3/22/2023",
      "endDate": "3/22/2023",
      "time": "09:30 am - 12:30 pm",
      "description": "First Workshop on the project work group \"beekeeping and beekeeping for recreational beekeepers\". We start with the construction of birdhouses. The workshop will take place in our location in Leinfelden - the meeting point is at the reception. All required materials will be provided. Please note, the number of participants is limited to 15. We are pleased about your participation. REGISTRATION via CGI For Good Tool, Link: https://cgi.wenabi.com/#/intv-global/intv/18915",
      "contact": "tanja.schmid@cgi.com"
    },
    {
      "id": 28,
      "activity": "Presentation about environmental protection and environmental management at CGI",
      "bu": "Germany North",
      "location": "Braunschweig / Sector VW",
      "startDate": "3/22/2023",
      "endDate": "3/22/2023",
      "time": "13:00 - 13.30",
      "description": "Presentation about environmental protection and environmental management at CGI by our trainee Eike. This presentation is for members of sector VW. You will be invited by mail.",
      "contact": "rika.bruemmer@cgi.com"
    },
    {
      "id": 281,
      "activity": "Movie Night",
      "bu": "Norway",
      "location": "Trondheim",
      "startDate": "3/22/2023",
      "endDate": "3/22/2023",
      "time": "17:00h",
      "description": "Watch the movie Breaking Boundaries with Johan Rockström and Sir David Attenbourough. It describes the different planetary boundaries behind 1.5° and presents the status.\n Backup movies: Don't Look Up, Downsizing. ",
      "contact": "fredrik.bertilsson@cgi.com"
    },
    {
      "id": 29,
      "activity": "Diversity Breakfast on the topic of \"statelessness",
      "bu": "Germany Central and South",
      "location": "Munich, Spixstreet 59",
      "startDate": "3/23/2023",
      "endDate": "3/23/2023",
      "time": "08.00 am - 09.30 am",
      "description": "The Diversity Breakfast is a get-together event where people can share, listen & exchange information about DE&I topics & initiatives with inspiring speakers and up-to-date topics. We are happy to kick off the DE&I breakfast event with the speaker Christiana Bukalo / creator of Statefree (https://statefree.world/). Please sign up early because arrangements have to be made in advance. If you are interested, please join the breakfast. REGISTRATION via CGI for Good Tool. Link: https://cgi.wenabi.com/#/profile/association/3697/intv/18875",
      "contact": "lucia.rea@cgi.com"
    },
    {
      "id": 30,
      "activity": "The Green Side Of BI: Data Driven Sustainability at Peab",
      "bu": "SBU",
      "location": "Everywhere (online)",
      "startDate": "3/23/2023",
      "endDate": "3/23/2023",
      "time": "12:15-12:45",
      "description": "Gustav Lindström and Håkan Nilsson is currently working with the large construction company Peab and will tell us more about their assignment there with data driven sustainability.\n Teams link: https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGM1ZThmZjItYmNmNy00NjU2LTgwMTYtN2U2YzVkMTBlMDMz%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%225cf784c4-7c36-45f9-af29-730084612d1b%22%7d",
      "contact": "kajsa.sennemarkaldman@cgi.com"
    },
    {
      "id": 31,
      "activity": "Online-Lecture: \"Energy and Sustainability - Reducing Costs with Renewable Energy\".",
      "bu": "BU North/GTO/Germany online",
      "location": "Everywhere",
      "startDate": "3/23/2023",
      "endDate": "3/23/2023",
      "time": "17:00-18:00",
      "description": "Online-Lecture on \"Energy and Sustainability - Reducing Costs with Renewable Energy\". The lecture will be in German by Diego Lobo-Guerro from AYKA-projects.\n \n - Agenda:\n § Conscious use of natural resources\n § Energy: forms, energy sectors (electricity, heat, mobility)\n § Units and costs made tangible (also merit order model)\n § Sector coupling done by oneself: case study EFH with photovoltaic system (PVA), heat pump and E-car\n § What can/must I do today in my personal environment for the urgently needed energy transition? Measures, contact persons, costs, support programs, tools\n \n Link to Microsoft-Teams: https://teams.microsoft.com/l/meetup-join/19%3ameeting_OTgwYzYxOTAtNDg0ZC00MWY3LTlhYjAtODRlNGI1NjAyM2U2%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%225c369621-baa1-4167-8bcf-80d21d85f9a6%22%7d",
      "contact": "olga.schwenecke@cgi.com"
    },
    {
      "id": 311,
      "activity": "Online Session with Berliner Tafel Deutschland e.V.",
      "bu": "BU Germany North",
      "location": "Berlin (Online)",
      "startDate": "3/23/2023",
      "endDate": "3/23/2023",
      "time": "12:00 - 13:00",
      "description": "Does this really have to go? The Berliner Tafel presents itself and the topic of the best-before date of food. The presentation lasts 30 min and is held in German.\nhttps://teams.microsoft.com/l/meetup-join/19%3aARv5rzY4pc2kfta8QbK190MQRoI2My21jSzvkh3ObDY1%40thread.tacv2/1678456117376?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%22547b5585-0b01-45bf-ac6a-cbce542adcab%22%7d",
      "contact": "klaudia.jozefowski@cgi.com"
    },
    {
      "id": 32,
      "activity": "Science and Technology Innovation Day - Ukrainian Pupils’ Support",
      "bu": "Germany Central and South",
      "location": "Munich, Spixstreet 59",
      "startDate": "3/24/2023",
      "endDate": "3/24/2023",
      "time": "04:00 pm - 07:00 pm",
      "description": "Career day for Ukrainian pupils interested in IT. We are proud to present our very own Science and Technology Innovation Day for Ukrainian pupils aged 15-19. Pupils full of drive work together on the group assignment with support from our experienced CGI mentors. A day full of inspiration and innovation awaits. Promotions for registration are done via social media channels and schools. Also, pupils from CGI members are very welcome to join this session. The only requirement is, that all participants must bring their personal laptops. REGISTRATION via CGI for Good Tool. Link: https://cgi.wenabi.com/#/profile/association/3697/intv/18878",
      "contact": "iryna.dovgun@cgi.com"
    },
    {
      "id": 33,
      "activity": "Panel Discussion with our Sustainability Experts & Enthusiasts",
      "bu": "BU Stockholm/GTO/IPBPS",
      "location": "Stockholm",
      "startDate": "3/24/2023",
      "endDate": "3/24/2023",
      "time": "12:05-12:55",
      "description": "Do you want know more about CGI’s sustainability work, internally as well as externally, or maybe just listen to our leaders’ and experts’ view on ESG? Then join this Panel Discussion! ​\n \n Several stakeholders with different perspectives, backgrounds and seniority will participate and discuss highly relevant and interesting sustainability subjects for about 45 minutes as a conclusion to the sustainability week. It will be a livestreaming that everyone from BU Stockholm, IPBPS and GTO can join during the lunch.​\n \n Take the chance to learn more and broader your perspective!\n \n LINK TO THE EVENT: https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2ZkNGVmNzEtNjllMy00ZGI5LWJmNzktMDQ1NDdjOWUzMzNi%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%2268e0e93b-e986-4149-b11d-168e07366385%22%7d",
      "contact": "hampus.petersson@cgi.com"
    },
    {
      "id": 34,
      "activity": "Sustainability lunch presentation",
      "bu": "Germany West",
      "location": "Darmstadt",
      "startDate": "3/24/2023",
      "endDate": "3/24/2023",
      "time": "12:30-13 Uhr",
      "description": "Title: \"Building a Sustainable Future: Strategies for a Greener Tomorrow\"\n \n Description: In this talk, we will explore the importance of sustainability in our daily lives and in the future of our planet. We will discuss the current state of the environment and the impact of human activities on natural systems. The talk will also cover practical strategies for individuals and organizations to implement in order to move towards a more sustainable future, including reducing waste, conserving resources, and embracing renewable energy. Join us for a thought-provoking discussion on the role we all play in building a sustainable future. LINK TO MTG : https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTA3Y2YxNGQtZjU5OS00OGMxLWJlM2EtZjVlYjJiM2FhMTQx%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%220cfd643e-71e6-41e8-9bba-ae200e54dbaa%22%7d",
      "contact": "mansuruddin.khan@cgi.com"
    },
    {
      "id": 341,
      "activity": "Virtueller Sustainability Lunch 2023",
      "bu": "Germany North / Hamburg",
      "location": "Online Meeting",
      "startDate": "3/24/2023",
      "endDate": "3/24/2023",
      "time": "12:00-13:30 Uhr",
      "description": "Monday until Friday the CSR HH Team will provide an information exchange and report on various topics including plastic waste, sustainable diets, and deforestation. With informative documents and a sustainability lunch at the end of the week, we want to show members what changes they can implement in their lives to become more sustainable. In the sustainability lunch on the 24th of March members can discuss sustainability topics and collectively think about a potential CSR event that we can organize in Hamburg. To join this session please register yourself via the CGI for good tool via the following link\:\n https://cgi.wenabi.com/#/intv-global/intv/19376?utm_source=wenabi_share&utm_medium=link&utm_campaign=other ",
      "contact": "lisa.wohling@cgi.com"
    },
    {
      "id": 3411,
      "activity": "Online Session with NABU Deutschland e.V ",
      "bu": "BU Germany North",
      "location": "Berlin (Online)",
      "startDate": "3/24/2023",
      "endDate": "3/24/2023",
      "time": "12:00-13:30 Uhr",
      "description": "Berlin can be green, too. NABU presents itself and its sustainable projects. The lecture lasts 60 min and takes place in German. \nhttps://teams.microsoft.com/l/meetup-join/19%3aARv5rzY4pc2kfta8QbK190MQRoI2My21jSzvkh3ObDY1%40thread.tacv2/1678893595237?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%22547b5585-0b01-45bf-ac6a-cbce542adcab%22%7d",
      "contact": "jessica.schibel@cgi.com"
    },
    {
      "id": 35,
      "activity": "Earth Hour",
      "bu": "SBU",
      "location": "Everywhere",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "20:30 - 21:30",
      "description": "On Saturday 25 March at 8:30 pm, millions of people across the globe unite to show that they care about the future of our planet, by switching off for one hour. Learn more & maybe sign up here: https://www.wwf.org.uk/earth-hour/10-facts-about-earth-hour",
      "contact": ""
    },
    {
      "id": 36,
      "activity": "Planting Trees (with Hessen Forst)",
      "bu": "Germany Central and South",
      "location": "Sulzbach",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "10.00 am - 04.00 pm",
      "description": "Many of you will remember that we started planting our CGI Forest (Grävenwiesbach) in April 2022. During the Sustainability Week we will again plant oak seedlings. The equipment is provided by our organization team. Please note weather-appropriate clothing for this initiative, good footwear is required (we recommend rubber boots). Also, physical well-being (food and drinks) is arranged. Everyone is welcome to help – members, family members, and also clients. If clients will join, please contact the organization team (André Zientek, Tina Hanisch) in advance! REGISTRATION via the CGI for Good tool - also further registration is described. Link: https://cgi.wenabi.com/#/profile/association/3697/intv/18858",
      "contact": "fabian.seel@cgi.com"
    },
    {
      "id": 37,
      "activity": "Planting Trees (Weil der Stadt)",
      "bu": "Germany Central and South",
      "location": "Leinfelden-Echterdingen",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "09:00 am - 17:00 pm",
      "description": " <strong>RESCHEDULE: </strong> The initiative was initially scheduled on Saturday, March 25, 2023 and was rescheduled by the Forest office to Saturday, April 22, 2023. If you are still interested to support this initaitve, please register via the CGI for Good Tool:  Link: https://cgi.wenabi.com/#/intv-global/intv/18927 \n An initiative of planting deciduous tree seedlings in the city forest “Weil der Stadt”. Weatherproof clothing and sturdy shoes are required. The working materials will be provided. You are welcome to bring your own spade and shovel. Everyone is invited to support this initiative, also family members and friends. The members who register for this initiative will receive further details (e.g. meeting point) from the contact person Tanja Schmid. REGISTRATION via the CGI for Good Tool. Link: https://cgi.wenabi.com/#/intv-global/intv/18927",
      "contact": "tanja.schmid@cgi.com"
    },
    {
      "id": 38,
      "activity": "Kölle putzmunter - Porz-Urbach",
      "bu": "Germany West",
      "location": "Köln",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "10:00 - 12:00",
      "description": "Ort: Verschiedene, im Vorfeld festgelegte Areale in den einzelnen Ortsteilen von Porz. Bei Interesse wenden Sie sich bitte wegen Termin (kann variieren) und weiteren Informationen (z.B. Treffpunkt) an den jeweiligen Bürgerverein.Wir werden gemeinsam Unrat auflesen, Grünflächen reinigen und wilde Müllkippen beseitigen.\n \n https://www.awbkoeln.de/koelle-putzmunter/aktion/?tx_koelleputzmunter_pi1%5Buid%5D=2656",
      "contact": "Monika.herweg@cgi.com"
    },
    {
      "id": 39,
      "activity": "Kölle putzmunter - Volkhoven/Weiler",
      "bu": "Germany West",
      "location": "Köln",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "10:00 - 14:00",
      "description": "Treffpunkt am Volkhovener Weg 209 /Simultanhalle. Putzmunteraktion zwischen Toni Welter Str. und Fühlinger Weg in Volkhoven.\n \n https://www.awbkoeln.de/koelle-putzmunter/aktion/?tx_koelleputzmunter_pi1%5Buid%5D=2670",
      "contact": "Monika.herweg@cgi.com"
    },
    {
      "id": 40,
      "activity": "Stadtputz Bochum / Cleaning Day",
      "bu": "Germany West",
      "location": "Bochum",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "10:00-13:00h",
      "description": "Collect Waste in the area around the office - USB Bochum organizes gloves, garbage bags etc, and you can help to make the city green, clean and nicer! Lets meet @ Bochum Alte Wittener Str",
      "contact": "angelika.stalitza@cgi.com"
    },
    {
      "id": 41,
      "activity": "Klimastadtbüro Bremerhaven - Climate Protection involving future generations",
      "bu": "BU North/GTO",
      "location": "Bremen",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "14:00h",
      "description": "The city of Bremerhaven in the far north of Germany faces many challenges: Tourism, harbor, logistics and heavy industry … so how to combine all this with climate protection? Bremerhaven was one of the first cities which designated themselves as \"Klimastadt (Climate City)\" and was also actively involving future generations  in political decisions with their unique \"Jugendklimarat (Youth climate counsil)\". We will support an active exchange on hot topics which occupy young peoples development and trust in political decisions. We will moderate an active but also open discussion among various stakeholders and provide innovative impulses from our consulting domain. Pupils from schools up to universities are invited to join and give feedback, which we will animate in small breakout sessions and thus at the end providing tangible resulta and demands of the future generations to administrations and political parties to pave the way for a better and brighter future for us all. Bremen and Bremerhaven have set a goal to become the first carbon-neutral \"Bundesland\" in Germany by 2038 and therefore have a strong need to focus on what's important.   ",
      "contact": "ralf-martin.tauer@cgi.com"
    },
    {
      "id": 42,
      "activity": "Dreck-weg-Tag",
      "bu": "Germany West",
      "location": "Düsseldorf",
      "startDate": "3/25/2023",
      "endDate": "3/25/2023",
      "time": "All Day",
      "description": "Since 1996 the \"Dreck-weg-Tag\" is an initiative of PRO DÜSSELDORF, a non-profit association supported by the city, businesses and citizens, which has made it its mission to make our city of Düsseldorf more liveable and lovable.\nThe Dreck-weg-Tag is organised by the PRO DÜSSELDORF board in close cooperation with the city's Department of the Environment and the waste disposal company AWISTA.\nTogehter with some colleagues we would like to take part in this event supporting the team of PRO DÜSSELDORF to clean up the city. Be also part of this team and register yourself on the webpage: https://www.dreck-weg-tag.de/",
      "contact": "robert.schulze@cgi.com"
    }
  ]