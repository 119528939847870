import React, { Suspense } from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme } from "@fluentui/react-northstar";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useTeamsFx } from "./sample/lib/useTeamsFx";
import TermsOfUse from "./TermsOfUse";
import "./App.css";
import TabConfig from "./TabConfig";
import Navbar from "./components/Navbar";
import Home from "./components/pages/HomePage/Home";
import Footer from "./components/pages/Footer/Footer";
import Program from "./components/pages/Program/Program";
import { FAQ } from "./components/pages/FAQ/FAQ";
import { PastActivities } from "./components/pages/PastActivities/PastActivities";
import { Contact } from "./components/pages/Contact/Contact";
import Infolog1 from "./components/pages/InfoLog/Infolog1";
import Program1 from "./components/pages/Program/Program1";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import DataEntry from "./components/pages/Form/DataEntry";
import Pastactivities1 from "./components/pages/PastActivities/Pastactivities1";
import Contact1 from "./components/pages/Contact/Contact1"



/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App(props) {
  const { theme } = useTeamsFx();
  const [token, setToken] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  microsoftTeams.initialize(() => {
    console.log("init");
  });
  useEffect(() => {
    const authTokenRequest = {
      successCallback: function (result) {
        setToken(result);
        setIsAuth(true);
        /*
          Enable only for debug or testing purposes
          */
        //console.log("Success: " + result);
      },
      failureCallback: function (error) {
        //console.log("Failure: " + error);
      },
    };
    microsoftTeams.authentication.getAuthToken(authTokenRequest);
  }, []);

  // console.log(token);

  return (
    <>
      {isAuth && (
        <Suspense fallback="loading">
          <Provider
            theme={theme || teamsTheme}
            styles={{ backgroundColor: "#eeeeee" }}
          >
          <div >
          
           
            <Router>
            <Navbar />
            <div className="mainBg"  >
             
            
            
              
              <Switch>
                <Route exact path="/">
                  <Redirect to="/tab" />
                </Route>
                <Route exact path="/home" component={Home} />
                <Route
                  exact
                  path="/program"
                  render={(props) => <Program1 token={token} />}
                />
                <Route
                  exact
                  path="/infoLog"
                  render={(props) => <Infolog1 token={token} />}
                />
                <Route exact path="/faq" component={FAQ} />
                <Route
                  exact
                  path="/pastActivities"
                  render={(props) => <Pastactivities1 token={token} />}
                />
                <Route
                  exact
                  path="/contact"
                  render={(props) => <Contact1 token={token} />}
                />
                <Route
                  exact
                  path="/dataCSR-enter"
                  render={(props) => <DataEntry token={token} /> }
                />
                <Route exact path="/termsofuse" component={TermsOfUse} />
                <Route exact path="/tab" component={Home} />
                <Route exact path="/config" component={TabConfig} />
              </Switch>
              </div>
            
              <Footer  />
            </Router>
            
          </div>
          </Provider>
        </Suspense>
      )}
    </>
  );
}
