import React from "react";
import UpperCard from "./../Program/UpperCard";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
  fontFamily:"Source Sans Pro', serif"
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "E8E8E8",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "14px 0",
    },
  },
  expanded: {},
  fontFamily:"Source Sans Pro', serif"
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const FAQ = () => {

  const {REACT_APP_MS_LINK} = process.env
  const email2 = " lukas.krappmann@cgi.com"
  const email3 = " vincentchinedu.obigwe@cgi.com"

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <UpperCard title=" FAQ" />
      <div
        style={{
          maxWidth: 900,
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 50,
          fontFamily:"Source Sans Pro', serif"
         
        }}
      >
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>What does ESG stand for ? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Environmental, Social and Governance / ESG is also known as Corporate Social Responsibility CSR and a long established tradition at CGI. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>I have an idea regarding ESG activities. Where do I submit my idea? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            If you have any suggestions for future ESG activities or improvements, please contact your local CSR representative.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Who do I contact if I have any questions concerning ESG? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            If you have any questions concerning ESG, you can contact your local ESG responsibles.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography>What is the purpose/background of the Sustainability week?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            As it is one of CGI’s six core values and embedded within our dream we want to give back to the environment, people and communities. While being part of a successful company and having financial security we believe it is our responsibility to be a caring corporate citizen.  Therefore, to bring more awareness towards the importance of Corporate Social Responsibility among our members we introduced the Sustainability week.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography>Who do I contact if I have a question regarding the ESG Teams App?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Regarding the ESG Teams App please forward your questions to 
            <a
                    href={REACT_APP_MS_LINK + "lukas.krappmann@cgi.com"}
                    style={{
                      color: "#716CAF",
                      textDecoration: "none"
                    }}
                  >
                    {email2}
            </a>  or  
            <a
                    href={REACT_APP_MS_LINK + "vincentchinedu.obigwe@cgi.com"}
                    style={{
                      color: "#716CAF",
                      textDecoration: "none"
                    }}
                  >
                     {email3}
            </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
