export default [
    {
        id : 1,
        heading: "Kick-off ",
        text:"Welcome and introduction to the sustainability week with Cecilia Pfannenstill, Kevin Linder and Marion Braams.",
        date: "20.03.2023",
        time: "12:15-12:50",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjBjOTRkNzQtZTNjOC00ZjVhLThlYjUtMDA1ZjgxZDVhZTI1%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%22343eb70c-4aa0-406f-b96a-f9730e0acb9a%22%7d"
    },
    {
        id : 2,
        heading: "AgileDX-Sustainability ",
        text: "This CGI solution is an operational-ready data exchange platform that captures, verifies, analyzes, and exchanges all your sustainability data.",
        date: "20.03.2023",
        time: "13:00-13:45",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTBmNmZmMDItNGUxYy00MWRhLWI4MzMtYzAyYTNmMjlkMDE2%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%225ffe4912-3922-4988-ab07-41f0dab9ae18%22%7d"
    },    
    {
        id : 3,
        heading: "AI4Whales",
        text: "How to use Earth Observation and Artificial Intelligence technologies to spot whales to avoid fatal collisions with ships.",
        date: "21.03.2023",
        time: "12:00-12:30",
        link: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NjkyZjRmZTgtOTlhYi00ZThjLWI0YTktYjc4ZGJhZWMzMTZh%40thread.v2/0?context=%7b%22Tid%22%3a%22b9fec68c-c92d-461e-9a97-3d03a0f18b82%22%2c%22Oid%22%3a%2298bb8e0c-e0f8-4022-9a39-fc2e90991e20%22%7d",
        readMore: "https://www.cgi.com/en/article/sustainability/using-earth-observation-data-and-machine-learning-prevent-ship-whale-collisions"
    },
]