export default [
    {
        id: 1,
        topic : "The Complexity of Sustainable Data Sharing (An overview video)",
        location: "SBU Wide",
        description:  "CGI Marketing video (1:20) to promote the importance of a data hub for sustainability data",
        link:  "https://cgi.mediaplatform.com/#!/video/3733/The+complexity+of+sustainable+data+sharing",
        
    },
    {
        id: 2,
        topic : "AgileDX-Sustainability Demo Videos with ESG Expert Robert Hoekstra ",
        location: "SBU Wide",
        description:  "4 videos explaining the details behind AgileDX and how it works \: Standards Management Data Management Data Capture and Exchange Validation and Benchmarking",
        link:  "https://cgi.mediaplatform.com/#!/viewPlaylist/366/3787",
        
    },
    {
        id: 3,
        topic : "CGI Experts Panel Discussion on the AgileDX-Sustainability Solution",
        location: "SBU Wide",
        description:  "a 12 min video explaining why CGI developed the AgileDX, the value to the clients, the key USP´s and how clients can get started",
        link:  "https://cgi.mediaplatform.com/#!/viewPlaylist/398",
        
    }]