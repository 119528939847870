import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker"
import moment from "moment"
import 'react-datepicker/dist/react-datepicker.css'
import "./Program.css";

toast.configure();
export const ProgramData = ({ token }) => {

  const {REACT_APP_API_URL} = process.env
  const {REACT_APP_API_MASTER} = process.env

  const url = `${REACT_APP_API_URL}csrProgram?code=${REACT_APP_API_MASTER}`

//  const mtoken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCIsImtpZCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCJ9.eyJhdWQiOiJhcGk6Ly93aGl0ZS1iZWFjaC0wMDBmNWZhMDMuYXp1cmVzdGF0aWNhcHBzLm5ldC85MzM0NjJhZi1iZjcyLTQzZTEtOGYyNC01YTQ1MzcyMWRlZTgiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9kODkwYjMzZi1iMjdhLTQyOWItYWI5Mi03MTdlYjlmNzdhNWQvIiwiaWF0IjoxNjM1MzMxNDg3LCJuYmYiOjE2MzUzMzE0ODcsImV4cCI6MTYzNTMzOTkyNSwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQWxLVTVQOXJZaU9VMUdVOUhPMWhBTjBVTE9sd1FMcWtubFMwek91N3pQU2J5UTdoZ0x1U3NmeGh3dmJiWlBpam8iLCJhbXIiOlsicHdkIiwicnNhIl0sImFwcGlkIjoiNWUzY2U2YzAtMmIxZi00Mjg1LThkNGItNzVlZTc4Nzg3MzQ2IiwiYXBwaWRhY3IiOiIwIiwiZGV2aWNlaWQiOiIwNTU2ZTM3Zi0yYTZlLTQwMDctODQ1ZS0xMjg0ZjAyOThjY2UiLCJmYW1pbHlfbmFtZSI6Ik9iaWd3ZSIsImdpdmVuX25hbWUiOiJWaW5jZW50IENoaW5lZHUiLCJpcGFkZHIiOiIzMS4xNy4yNTQuOTIiLCJuYW1lIjoiVmluY2VudCBDaGluZWR1IE9iaWd3ZSIsIm9pZCI6IjU0NGQ3M2JjLTdkYmUtNDgyNi1hMTRiLTNmZDlhZjlhZjEyYSIsInJoIjoiMC5BUTBBUDdPUTJIcXltMEtya25GLXVmZDZYY0RtUEY0Zks0VkNqVXQxN25oNGMwWWhBT0UuIiwic2NwIjoiYWNjZXNzX2FzX3VzZXIiLCJzdWIiOiJWZnVvVVA2c2V6V0h2OGNqX2ZsRmFoa1QwN191WWRua1ZrQndGVGxpUkVNIiwidGlkIjoiZDg5MGIzM2YtYjI3YS00MjliLWFiOTItNzE3ZWI5Zjc3YTVkIiwidW5pcXVlX25hbWUiOiJ2aW5jZW50Y2hpbmVkdS5vYmlnd2VAY2dpY2xvdWQuZGUiLCJ1cG4iOiJ2aW5jZW50Y2hpbmVkdS5vYmlnd2VAY2dpY2xvdWQuZGUiLCJ1dGkiOiJIRWpzaVUzdnBVdTFQa3plc1dQc0FBIiwidmVyIjoiMS4wIn0.m-cH0Dnuf6qP0tLZSlUuXzAEIhEvf5jkoNntKmFKsbRnQbcEaOg0O4mxwrpcQrFhqiSZvR7EQOtlZXNH0FWb_Haj8oo22ROGRnxw7h-EBcWGThSIZQAqwz1KY63SC75Q6OcaPgG3SOBalFqgZ8ABVZ9moxgwE910r1sIOqNoC90j5rgHm60ViW7rGVGGsqhBeS5MEGAugSM_tiFhF1Ud7iPCqC-IyPaBwXtMO-TzWyZsAkwSnzL7G5mEjz1ZwgbEtxRMSKFSL0Kaa6onax0XYfdlmqKyvEbUHdaH6-YBWYLnwbSLbmqOiYKHcMBOahHA9xzPXu1uYgQOehzIS2NAbQ"

  
  const [data, setData] = useState({
    activity: "",
    location: "",
    description: "",
    time: "",
    memberContact: "",
    email: "",
    memberContact2: "",
    email2: "",
  });
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)

  const headers = {
    'Authorization': "Bearer " + token,
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }


  function submit(e) {
    e.preventDefault();
    console.log(selectedDate)
    console.log(selectedEndDate)
    const mainDate = moment(selectedDate).format("DD.MM.YYYY")
    const mainEndDate = moment(selectedEndDate).format("DD.MM.YYYY")
    console.log(mainDate) 
    console.log(mainEndDate)   

    Axios.post(
      url,
      {
        activity: data.activity,
        location: data.location,
        description: data.description,
        date: mainDate,
        endingDate: mainEndDate,
        time: data.time,
        memberContact: data.memberContact,
        email: data.email,
        memberContact2: data.memberContact2,
        email2: data.email2,
      },
      {
        headers: headers,
      }
    ).then((res) => {
      console.log(res.data);
    }).catch((res) => {
      console.log(res.data);
    });

    toast.success("Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });

    setData({
      activity: "",
      location: "",
      description: "",
      time: "",
      memberContact: "",
      email: "",
      memberContact2: "",
      email2: "",
    });
  }

  return (
    <>
      <div className="formDiv">
        <form onSubmit={(e) => submit(e)} id="entryForm">
          <label for="location" className="formLabel">
            Activity
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.activity}
            type="text"
            id="activity"
            name="activity"
            placeholder="Activity.."
            className="formInput"
          ></input>

          <label for="location" className="formLabel">
            Location
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.location}
            type="text"
            id="location"
            name="location"
            placeholder="Location.."
            className="formInput"
          ></input>

          <label for="description" className="formLabel">
            Description
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.description}
            type="text"
            id="description"
            name="description"
            placeholder="Description of activity.."
            className="formInput"
          ></input>

          <label for="date" className="formLabel">
            Start Date
          </label>
          <DatePicker
              className = "formInput"
              selected = {selectedDate}
              onChange = {date => setSelectedDate(date)}
              dateFormat='dd.MM.yyyy'
              wrapperClassName="datePicker"
            />

          <label for="Enddate" className="formLabel">
            Ending Date
          </label>
          <DatePicker
              className = "formInput"
              selected = {selectedEndDate}
              onChange = {endDate => setSelectedEndDate(endDate)}
              dateFormat='dd.MM.yyyy'
              wrapperClassName="datePicker"
            />

          <label for="time" className="formLabel">
            Time
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.time}
            type="text"
            id="time"
            name="time"
            placeholder="Please use format 06:00am"
            className="formInput"
          ></input>

          <label for="memberContact" className="formLabel">
            Contact
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.memberContact}
            type="text"
            id="memberContact"
            name="memberContact"
            placeholder="Name of the Contact"
            className="formInput"
          ></input>

          <label for="email" className="formLabel">
            Email
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.email}
            type="email"
            id="email"
            name="email"
            placeholder="Contact Email.."
            className="formInput"
          ></input>

          <label for="memberContact2" className="formLabel">
            Second Contact
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.memberContact2}
            type="text"
            id="memberContact2"
            name="memberContact2"
            placeholder="Name of the Second Contact"
            className="formInput"
          ></input>

          <label for="email" className="formLabel">
            Second Contact Email
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.email2}
            type="email"
            id="email2"
            name="email2"
            placeholder="Second Contact Email.."
            className="formInput"
          ></input>

          <input type="submit" value="Submit" className="formSubmit" />
        </form>
      </div>
    </>
  );
};
