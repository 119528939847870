
//import {  Link } from "react-router-dom";
//import ReactDOM from 'react-dom';
import React, { useState } from "react";
import { FontSizes } from '@fluentui/theme';
import contactData from "../data/contacts1";
import styles from "./Program.module.css";
import Table from "../TableContact1";
import { Contact } from "./Contact";

const mystyle = {
  
  fontWeight: "400",
  color: "#200A58",
  fontSize: "38px",
  fontFamily: "Source Sans Pro, serif",
  textAlign:"center"
};

const Contact1 = () => {
  const [contact] = useState([...contactData]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  
  const handleSort = (column) => {
    setSortOrder((prevSortOrder) => {
      if (prevSortOrder.column === column) {
        return {
          column: column,
          order: prevSortOrder.order === 'asc' ? 'desc' : 'asc',
        };
      } else {
        return {
          column: column,
          order: 'asc',
        };
      }
    });
  
  
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const items2 = [...contact]
  .filter((item) => {
    return (
      item.esgbu.toLowerCase().includes(searchQuery.toLowerCase()) 
    );
  })
  .sort((a, b) => {
    let result = 0;
  
    if (sortOrder.column === 'esgbu') {
        result = a.esgbu.localeCompare(b.esgbu);
        
    } else if (sortOrder.column === 'responsiblemember') {
      result = a.responsibleMember.localeCompare(b.responsibleMember);
    }
  
    return sortOrder.order === 'asc' ? result : -result;
  });
  
  
  
  
  return ( <div>
     
       <div className="infolog1--text"> 
         <h1 style={mystyle}> Local ESG responsible</h1>
         </div>
    
    
    <div>
    <main className={styles.container}>
      
      <div className={styles.wrapper}>
      <input 
              type="text"
              placeholder="Search BU..."
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: "10px",
               
                border: "1px solid #ccc",
                fontFamily: "Source Sans Pro",
                fontSize: "14px",
                width: "400px",
                float: "right", marginBottom: "5px" 
              }}
            />
      
        <Table data={items2} rowsPerPage={5} handleSort={handleSort} sortOrder={sortOrder} />
      </div>
    </main></div>
    </div>
  );
};


export default Contact1;
