import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { MdCopyright } from "react-icons/md";

function Footer() {
  return (
    <div className="footer-container">
      <Link to="/" className="social-logo">
        <MdCopyright className="navbar-icon" />
        CGI SCE Sustainability week App.
      </Link>
         </div>
  );
}

export default Footer;