import React, { useState } from "react";

import useTable from "../hooks/useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";

const Table = ({ data, rowsPerPage, handleSort, sortOrder }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}><div style={{display: "flex"}}>
        ESG BU
        <button onClick={() => handleSort('esgbu')} style={{ marginLeft: '0.25rem' }}>
          <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div></th>
            <th className={styles.tableHeader}><div style={{display: "flex"}}>
        Responsible Member
        <button onClick={() => handleSort('responsiblemember')} style={{ marginLeft: '0.25rem' }}>
          <i className={`fa fa-sort ${sortOrder === 'asc' ? '-up' : '-down'}`} />
        </button>
      </div></th>
            <th className={styles.tableHeader}>Contact</th>
            
          </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <tr className={styles.tableRowItems} key={el.id}>
              <td className={styles.tableCell}>{el.esgbu}</td>
              <td className={styles.tableCell}>
                {el.responsibleMember}
                {el.responsibleMember2 && (
                  <>
                    <hr />
                    {el.responsibleMember2}
                  </>
                )}
                {el.responsibleMember3 && (
                  <>
                    <hr />
                    {el.responsibleMember3}
                  </>
                )}
              </td>
              <td className={styles.tableCell}>
                <a href={`mailto:${el.contact}`}>{el.contact}</a>
                {el.contact2 ? (
                  <>
                    <hr />
                    <a href={`mailto:${el.contact2}`}>{el.contact2}</a>
                  </>
                ) : null}
                {el.contact3 ? (
                  <>
                    <hr />
                    <a href={`mailto:${el.contact3}`}>{el.contact3}</a>
                  </>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;