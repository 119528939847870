import React, { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export const PastActivitiesData = ({ token }) => {

  const {REACT_APP_API_URL} = process.env
  const {REACT_APP_API_MASTER} = process.env

  var url =
  `${REACT_APP_API_URL}csrPastActivities?code=${REACT_APP_API_MASTER}`

  const [data, setData] = useState({
    activity: "",
    location: "",
    description: "",
    contact: "",
    email: "",
    contact2: "",
    email2: "",
  });

  const headers = {
    'Authorization': "Bearer " + token,
  };

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }

  function submit(e) {
    e.preventDefault();
    Axios.post(
      url,
      {
        activity: data.activity,
        location: data.location,
        description: data.description,
        contact: data.contact,
        email: data.email,
        contact2: data.contact2,
        email2: data.email2,
      },
      {
        headers: headers,
      }
    ).then((res) => {
      console.log(res.data);
    });

    toast.success("Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });

    setData({
      activity: "",
      location: "",
      description: "",
      contact: "",
      email: "",
      contact2: "",
      email2: "",
    });
  }

  return (
    <>
      <div className="formDiv">
        <form onSubmit={(e) => submit(e)} id="entryForm">
          <label for="location" className="formLabel">
            Activity
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.activity}
            type="text"
            id="activity"
            name="activity"
            placeholder="Activity.."
            className="formInput"
          ></input>

          <label for="location" className="formLabel">
            Location
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.location}
            type="text"
            id="location"
            name="location"
            placeholder="Location.."
            className="formInput"
          ></input>

          <label for="member" className="formLabel">
            Description
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.description}
            type="text"
            id="description"
            name="description"
            placeholder="Description of activity.."
            className="formInput"
          ></input>

          <label for="member" className="formLabel">
            Contact
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.contact}
            type="text"
            id="contact"
            name="contact"
            placeholder="Name of the Contact"
            className="formInput"
          ></input>

          <label for="email" className="formLabel">
            Email
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.email}
            type="email"
            id="email"
            name="email"
            placeholder="Contact Email.."
            className="formInput"
          ></input>

          <label for="member" className="formLabel">
            Second Contact
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.contact2}
            type="text"
            id="contact2"
            name="contact2"
            placeholder="Name of the Second Contact"
            className="formInput"
          ></input>

          <label for="email" className="formLabel">
            Second Contact Email
          </label>
          <input
            onChange={(e) => handle(e)}
            value={data.email2}
            type="email2"
            id="email2"
            name="email2"
            placeholder="Second Contact Email.."
            className="formInput"
          ></input>

          <input type="submit" value="Submit" className="formSubmit" />
        </form>
      </div>
    </>
  );
};
