
//import {  Link } from "react-router-dom";
//import ReactDOM from 'react-dom';
import React, { useState } from "react";
import { FontSizes } from '@fluentui/theme';
import eventsData from "../data/events1";
import styles from "./Program.module.css";
import Table from "../TablePast1";

const mystyle = {
  
  fontWeight: "400",
  color: "#200A58",
  fontSize: "38px",
  fontFamily: "Source Sans Pro, serif",
  textAlign:"center"
};

const Pastavtivities1 = () => {
  const [events] = useState([...eventsData]);
  const filteredItems = events.filter((item) => {
    const itemDate = new Date(item.endDate);
    const currentDate = new Date();
    return itemDate < currentDate;
});

const [sortOrder, setSortOrder] = useState('asc');
const [searchQuery, setSearchQuery] = useState('');

const handleSort = (column) => {
  setSortOrder((prevSortOrder) => {
    if (prevSortOrder.column === column) {
      return {
        column: column,
        order: prevSortOrder.order === 'asc' ? 'desc' : 'asc',
      };
    } else {
      return {
        column: column,
        order: 'asc',
      };
    }
  });


};
const handleSearch = (e) => {
  setSearchQuery(e.target.value);
};


const items2 = [...filteredItems]
.filter((item) => {
  return (
    item.activity.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.location.toLowerCase().includes(searchQuery.toLowerCase()) 
  );
})


.sort((a, b) => {
  let result = 0;

  if (sortOrder.column === 'bu') {
      result = a.bu.localeCompare(b.bu);
      
  }else if (sortOrder.column === 'location') {
    result = a.location.localeCompare(b.location);
  }

  return sortOrder.order === 'asc' ? result : -result;
});



  return ( <div>
     
       <div className="infolog1--text"> 
         <h1 style={mystyle}> Past CSR Activities</h1>
         </div>
    
    
    <div>
    <main className={styles.container}>
      
      <div className={styles.wrapper}>
      <input 
              type="text"
              placeholder="Search Activity, Location or Description..."
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: "10px",
               
                border: "1px solid #ccc",
                fontFamily: "Source Sans Pro",
                fontSize: "14px",
                width: "400px",
                float: "right", marginBottom: "5px" 
              }}
            />
      
        <Table data={items2} rowsPerPage={4} handleSort={handleSort} sortOrder={sortOrder}/>
      </div>
    </main></div> 
    </div> 
  );
};


export default Pastavtivities1;
